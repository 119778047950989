
const hangarLineOptions = {
  kefLine: "KEF/LINE",
  rkvLine: "RKV/LINE",
  KefHangar: "KEF/HANGAR",
  kefHangarBay1: "KEF/HANGAR/BAY 1",
  kefHangarBay2: "KEF/HANGAR/BAY 2",
  kefHangarBay3: "KEF/HANGAR/BAY 3",
  kefHangarBay4: "KEF/HANGAR/BAY 4",
  kefHangarBay5: "KEF/HANGAR/BAY 5",
  kefHangarBay6: "KEF/HANGAR/BAY 6",
  kefHangarBay7: "KEF/HANGAR/BAY 7",
  kefHangarBayH885: "KEF/HANGAR/H885",
  miaLine: "MIA/LINE",
  amsLine: "AMS/LINE",
  seaLine: "SEA/LINE",
  IadLine: "IAD/LINE",
  denLine: "DEN/LINE",
  noLoc: "NO LOC",
} as const;

const hangarRkvLineOptions = {
  kefLine: "KEF/LINE",
  rkvLine: "RKV/LINE",
  KefHangar: "KEF/HANGAR",
  kefHangarBay1: "KEF/HANGAR/BAY 1",
  kefHangarBay2: "KEF/HANGAR/BAY 2",
  kefHangarBay3: "KEF/HANGAR/BAY 3",
  kefHangarBay4: "KEF/HANGAR/BAY 4",
  kefHangarBay5: "KEF/HANGAR/BAY 5",
  kefHangarBay6: "KEF/HANGAR/BAY 6",
  kefHangarBay7: "KEF/HANGAR/BAY 7",
  kefHangarBayH885: "KEF/HANGAR/H885",
  miaLine: "MIA/LINE",
  amsLine: "AMS/LINE",
  seaLine: "SEA/LINE",
  IadLine: "IAD/LINE",
  denLine: "DEN/LINE",
  noLoc: "NO LOC",
} as const;

const statusOptions = {
  complete: "COMPLETE",
  actv: "ACTV",
  commit: "COMMIT",
  cancel: "CANCEL",
  inWork: "IN WORK",
  pause: "PAUSE",
} as const;

const hangarPlanLineOptions = {
  kefHangarBay1: "KEF/HANGAR/BAY 1",
  kefHangarBay2: "KEF/HANGAR/BAY 2",
  kefHangarBay3: "KEF/HANGAR/BAY 3",
  kefHangarBay4: "KEF/HANGAR/BAY 4",
  kefHangarBay5: "KEF/HANGAR/BAY 5",
  kefHangarBay6: "KEF/HANGAR/BAY 6",
  kefHangarBay7: "KEF/HANGAR/BAY 7",
} as const;

export {
  hangarLineOptions,
  hangarRkvLineOptions,
  statusOptions,
  hangarPlanLineOptions
}