import { FunctionComponent } from 'react';
import { Input, DatePicker } from '../../MaterialUiComponents';
import { Grid } from '@mui/material';
import { Request } from '../../types/capabilityList';

interface TextFieldsProps {
    request: Request,
    viewMode: boolean,
    setRequest: Function,
}

const DateTextAndJiraLink: FunctionComponent<TextFieldsProps> = ({ request, setRequest, viewMode }) => {
    return (
        <Grid style={{ marginTop: '32px' }} container justifyContent="center" alignItems="center" direction="row">
            <Grid item xs={12} sm={12} md={2} lg={2}>
            <DatePicker
                width={90}
                disabled={viewMode}
                onDateChange={(date: Date) => {
                    setRequest((prevState: Request) => {
                        return {
                            ...prevState,
                            accepted_date: date,
                        };
                    });
                }}
                
                type={"Date"}
                label="Accepted date by Samgöngustofa"
                selected={request.accepted_date ? new Date(request.accepted_date) : new Date()}
            />
            </Grid>
            <Grid item style={{ marginLeft: '16px' }} xs={12} sm={12} md={4} lg={4}  >
                <Input
                    disabled={viewMode}
                    multiline={true}
                    help_text={""}
                    label_text={"Accepted text"}
                    value={request.accepted_text}
                    onTextChange={(text: string) => setRequest((prevState: Request) => ({ ...prevState, accepted_text: text }))}
                    type="text"
                    width={90}
                />
            </Grid>
            <Grid item style={{ marginLeft: '16px' }} xs={12} sm={12} md={4} lg={4}  >
                <Input
                    disabled={viewMode}
                    multiline={true}
                    help_text={""}
                    label_text={"Jira link optional"}
                    value={request.jira_link}
                    onTextChange={(text: string) => setRequest((prevState: Request) => ({ ...prevState, jira_link: text }))}
                    type="text"
                    width={90}
                />
            </Grid>
        </Grid>
    )
}
export default DateTextAndJiraLink;

