import { RKVLineWPOFiltersAction } from "../types/actions";
import { LineFilters } from "../types/line";
import { DateTime } from "luxon";
import { statusOptions, hangarRkvLineOptions } from '../properties/lineProperties';

const currentDate = new Date();
const rkvLineDateFrom = DateTime.fromJSDate(currentDate).minus({ days: 30 });
const rkvLineDateTo = DateTime.fromJSDate(currentDate).plus({ days: 30 });

const filtersInitial = {
	rkvLinefilters:{
		dateFrom: rkvLineDateFrom.toJSDate(),
		dateTo: rkvLineDateTo.toJSDate(),
		tail: "",
		filterByHangers: [hangarRkvLineOptions.rkvLine],
		filterByStatus: [statusOptions.commit],
		dueDateHourCycles: false,
		dueDateExceedsFifteen: false,
	} as LineFilters,	
}

export const rkvLineReducer = (state = { ...filtersInitial }, action: RKVLineWPOFiltersAction) => {
	switch (action.type) {
		case 'RKV_LINE_WORK_PACKAGE_OVERVIEW_FILTERS': 
			return { ...state, rkvLinefilters: action.rkvLinefilters };
		default:
			return state;
	}
}