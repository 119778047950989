import { getToken } from '../global/TokenValidator';
import { User } from '../types/user';
import { useDispatch } from 'react-redux';


const fetchUserImage = async (userName:string) => {
    const token = await getToken();
    const response = await fetch(`/api/maintener/fetch-user-image/${userName}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': token
        }
    });
    const blob = await response.blob();
    return URL.createObjectURL(blob);
};

const fetchAllImages = async (allEmployees: User[]) => {
    const imagePromises = allEmployees.map(async employee => {
        const userName = employee.userName?.split("@")[0];
        const image = await fetchUserImage(userName);
        return { image, userName };
    });
    return Promise.all(imagePromises);
};

const useFetchAllUserProfilePictures = () => {
    const dispatch = useDispatch();

    const fetchAllUserProfilePictures = async (allEmployees: User[], setIsImagesLoading: Function) => {
        setIsImagesLoading(true);
        try {
            const allImages = await fetchAllImages(allEmployees);
            dispatch({ type: "GET_ALL_IMAGES", all_images: allImages });
        } catch (error) {
            console.error("Error fetching images:", error);
        } finally {
            setIsImagesLoading(false);
        }

        // Fallback: Stop loading after 60 seconds in case no response
        setTimeout(() => {
            setIsImagesLoading(false);
        }, 60000);
    };

    return fetchAllUserProfilePictures;
};

export { fetchUserImage, fetchAllImages, useFetchAllUserProfilePictures };