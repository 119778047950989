import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Tabs,LinearProgress } from '../../MaterialUiComponents';
import LiteHandover from '../Lite/LiteHandover';
import LineWorkPackageOverview from '../../components/Line/LineWorkPackageOverview';
import { workplaceEnum } from '../../components/Line/LineWorkPackageOverview';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import LineSupervisorPlanedTasks from "../Line/LineSupervisorPlanedTasks";
import TasksApi from '../../api/tasks';

function ReykjavikLineSupervisor() {
    const [tabValue, setTabValue] = useState(0);
    const dashTails = ["TF-FXG", "TF-FXI", "TF-FXK", "TF-FXA", "TF-FXH", "TF-FXE"];
    const [loading, setLoading] = useState<boolean>(true);
    const [allTails, setAllTails] = useState(Array<string>());

    const store = useSelector((state: RootState) => state);
    const groups = store.user.me.user_clearence_groups;
    const isAdmin = groups.includes("Rvk Line Edit") || groups.includes("Rvk Line View");

    useEffect(() => {
        setLoading(true);
        TasksApi.GetAllTailNumbers().then(res => {
            setAllTails(res.data);
          });
    }, [tabValue]);

    return (
        <Grid>
            {loading && <LinearProgress />}
            <Tabs
                tabNames={["Calendar", "Workpackage Overview",  "Handover",]}
                onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
                tabValue={tabValue}
            />
            {tabValue === 0 && <LineSupervisorPlanedTasks isAdmin={isAdmin} accessGroups={groups} location="RKV/LINE" setLoading={setLoading}/>}
            {tabValue === 1 && <Grid> < LineWorkPackageOverview allTails={allTails} setLoadingProp={(loading: boolean) => setLoading(loading)} workPlace={workplaceEnum.rkvLine} /></Grid>}
            {tabValue === 2 && <LiteHandover setLoading={setLoading} workPlace='RVK' allTails={dashTails} />}
        </Grid>
    );
};
export default ReykjavikLineSupervisor;