import { LineWPOFiltersAction } from "../types/actions";
import { LineFilters } from "../types/line";
import { DateTime } from "luxon";
import { statusOptions, hangarLineOptions } from '../properties/lineProperties';

const currentDate = new Date();
const lineDateFrom = DateTime.fromJSDate(currentDate).minus({ days: 30 });
const lineDateTo = DateTime.fromJSDate(currentDate).plus({ days: 30 });

const filtersInitial = {
	linefilters:{
		dateFrom: lineDateFrom.toJSDate(),
		dateTo: lineDateTo.toJSDate(),
		tail: "",
		filterByHangers: [hangarLineOptions.kefLine],
		filterByStatus: [statusOptions.commit],
		dueDateHourCycles: false,
		dueDateExceedsFifteen: false,
	} as LineFilters,	
}

export const lineReducer = (state = { ...filtersInitial }, action: LineWPOFiltersAction) => {
	switch (action.type) {
		case 'LINE_WORK_PACKAGE_OVERVIEW_FILTERS': 
			return { ...state, linefilters: action.linefilters };
		default:
			return state;
	}
}