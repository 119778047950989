import { LiteWPOFiltersAction } from "../types/actions";
import { LiteFilters } from "../types/lite";
import { DateTime } from "luxon";
import { statusOptions } from '../properties/lineProperties';
import { hangarLiteOptions } from '../properties/liteProperties';

const currentDate = new Date();
const liteDateFrom = DateTime.fromJSDate(currentDate).minus({ weeks: 1 });
const liteDateTo = DateTime.fromJSDate(currentDate).plus({ weeks: 3 });

const filtersInitial = {
	litefilters:{
		dateFrom: liteDateFrom.toJSDate(),
		dateTo: liteDateTo.toJSDate(),
		tail: "",
		filterByHangers: [hangarLiteOptions.kefHangarBay4, hangarLiteOptions.kefHangarBay5, hangarLiteOptions.liteAirsite],
		filterByStatus: [statusOptions.commit],
		dueDateHourCycles: false,
		dueDateExceedsFifteen: false,
	} as LiteFilters,	
}

export const liteReducer = (state = { ...filtersInitial }, action: LiteWPOFiltersAction) => {
	switch (action.type) {
		case 'LITE_WORK_PACKAGE_OVERVIEW_FILTERS': 
			return { ...state, litefilters: action.litefilters };
		default:
			return state;
	}
}
