import React, { KeyboardEvent, useState, useEffect, FunctionComponent } from 'react';
import lineApi from './../../api/line';
import taskApi from './../../api/tasks'
import { Grid, TableRow, TableCell, IconButton, Button, Autocomplete, TextField, FormControlLabel, Checkbox, Chip } from '@mui/material'
import { Table, DatePicker, Dialog, LinearProgress, Tooltip, SelectMultiple, Input } from '../../MaterialUiComponents';
import { LinePackageOverviewObj } from '../../types/line';
import { Part } from '../../types/tasks';
import { makeStyles } from '@mui/styles';
import { ShoppingCart, ArrowUpward, ArrowDownward, StopCircle, AlarmOn } from '@mui/icons-material';
import { redirectToMxi } from './../../config/mxiProvider';
import { Link } from '@mui/icons-material';
import { ManHoursEdit } from '../../types/workPackage';
import manHoursApi from './../../api/workPackages';
import { hangarLineOptions, hangarRkvLineOptions, statusOptions } from '../../properties/lineProperties';
import { hangarLiteOptions } from '../../properties/liteProperties';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer';

const useStyles = makeStyles(theme => ({
  backgroundColorRed: {
    backgroundColor: 'red'
  },
  Morning: {
    backgroundColor: '#DDA0DD', // Orange Day
  },
  Night: {
    backgroundColor: '#FFA500', //purple light Night
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "gray"
    }
  }
}));

export enum workplaceEnum {
  line = "Line",
  lite = "Lite",
  rkvLine = "RkvLine",
};

type LineProps = {
  allTails: Array<string>,
  setLoadingProp: Function,
  workPlace: workplaceEnum,
}

const LineWorkPackageOverview: FunctionComponent<LineProps> = ({ allTails, setLoadingProp, workPlace }) => {
  const dispatch = useDispatch();
  const store = useSelector((state: RootState) => state);
  const classes = useStyles();
  const [reports, setReports] = useState(Array<LinePackageOverviewObj>());
  const [partsInOrder, setPartsInOrder] = useState(Array<Part>());
  const [unavailableParts, setUnAvalableParts] = useState(Array<Part>());
  const [deleteOutstandingPartsDialogOpen, setDeleteOutstandingPartsDialogOpen] = useState(false);
  const [stoppedPackages, setStoppedPackages] = useState(Array<LinePackageOverviewObj>());
  const [currentTaskName, setCurrentTaskName] = useState<string>("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [stoppedDialogOpen, setStoppedDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listenerRefresh, setListenerRefresh] = useState(false);
  const [startDateLaterThanDueDateOpen, setStartDateLaterThanDueDateOpen] = useState(false);
  const [rowClicked, setRowClicked] = useState(-1);
  const [manHours, setManHours] = useState<ManHoursEdit>({ manHours: 0, showManHoursEdit: false, index: -1, woBarcode: "" });

  useEffect(() => {
    refresh();
    //Using keyup instead of keydown to avoid multiple fetch calls
    document.addEventListener('keyup', R_key_Pressed, false);
  }, []);
  // Trigger refresh from listener. Neccessary because the listener uses state and functions from when it's initially loaded. 
  // So to work around, we change state of boolean and trigger refresh here when that boolean changes.
  useEffect(() => {
    if (listenerRefresh === true) {
      refresh();
    }
  }, [listenerRefresh]);

  // By adding return function inside, this renders only when component unmounts. So we delete the listener when unmounting
  useEffect(() => {
    return () => {
      document.removeEventListener('keyup', R_key_Pressed, false);
    }
  }, []);

  const R_key_Pressed = (event: any) => {
    const keyCode = event.keyCode;
    // If R is clicked then refresh. Doesn't run if in the autocomplete field to type
    if (keyCode === 82 && !document.activeElement?.classList.contains("MuiAutocomplete-input")) {
      event.preventDefault();
      setListenerRefresh(true);
    }
  }
  const handleRowClicked = (index: number) => {
    if (rowClicked === index) {
      setRowClicked(-1);
    }
    else {
      setRowClicked(index);
    }
  }

  const refresh = () => {
    setLoadingProp(true);
    if (workPlace === workplaceEnum.line) {
      getLinePacketsOverview(store.line.linefilters.dateFrom,
        store.line.linefilters.dateTo,
        store.line.linefilters.filterByStatus,
        store.line.linefilters.tail,
        store.line.linefilters.filterByHangers,
        store.line.linefilters.dueDateHourCycles,
        store.line.linefilters.dueDateExceedsFifteen,
     );
    }
    else if(workPlace === workplaceEnum.rkvLine) {
      getLinePacketsOverview(store.rkvLine.rkvLinefilters.dateFrom,
        store.rkvLine.rkvLinefilters.dateTo,
        store.rkvLine.rkvLinefilters.filterByStatus,
        store.rkvLine.rkvLinefilters.tail,
        store.rkvLine.rkvLinefilters.filterByHangers,
        store.rkvLine.rkvLinefilters.dueDateHourCycles,
        store.rkvLine.rkvLinefilters.dueDateExceedsFifteen,
      );
    }
    else { // lite
      getLinePacketsOverview(store.lite.litefilters.dateFrom,
        store.lite.litefilters.dateTo,
        store.lite.litefilters.filterByStatus,
        store.lite.litefilters.tail,
        store.lite.litefilters.filterByHangers,
        store.lite.litefilters.dueDateHourCycles,
        store.lite.litefilters.dueDateExceedsFifteen,
      );
    }
  }

  const getLinePacketsOverview = (fromDate: Date, toDatem: Date, selectedStatus: string[], selectedTail: string, selectedHangarStatus: string[], isDueDayChecked: boolean, isDueDateExceedsFifteenChecked: boolean) => {
    lineApi.GetLinePacketsOverview(fromDate, toDatem, selectedStatus, selectedTail!, selectedHangarStatus, isDueDayChecked,isDueDateExceedsFifteenChecked, true).then(res => {
      setReports(res.data);
      setLoadingProp(false);
      setListenerRefresh(false);
    })
  }

  const openDialog = (woBarcode: string, wo_name: string) => {
    setCurrentTaskName(wo_name);
    setDeleteDialogOpen(true);
    taskApi.GetPartsByWoBarcode(woBarcode).then(res => {
      setPartsInOrder(res.data);
    });
  }

  const getOutstandingMaterials = () => {
    setDeleteOutstandingPartsDialogOpen(true);
    setUnAvalableParts([] as Array<Part>);
    setLoading(true);
    let currentTasks = [];
    for (let i = 0; i < reports.length; i++) {
      currentTasks.push(reports[i].wo_barcode);
    }

    let tempFromDate: Date;
    let tempToDate: Date;

    if (workPlace === workplaceEnum.line ) {
      tempFromDate = store.line.linefilters.dateFrom;
      tempToDate = store.line.linefilters.dateTo;
    }
    else if(workPlace === workplaceEnum.rkvLine){
      tempFromDate = store.rkvLine.rkvLinefilters.dateFrom;
      tempToDate = store.rkvLine.rkvLinefilters.dateTo;
    }
    else {
      tempFromDate = store.lite.litefilters.dateFrom;
      tempToDate = store.lite.litefilters.dateTo;
    }

    lineApi.GetAllOutstandingMaterialsByWo(tempFromDate, tempToDate, currentTasks).then(res => {
      setLoading(false);
      if (res.status === true) {
        setUnAvalableParts(res.data);
      }
    })
  }

  const onPackageOnHold = () => {
    setStoppedPackages(reports.filter(s => s.is_wo_on_hold === true));
    setStoppedDialogOpen(true);
  }

  const handleKeyDownEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      updateManHoursField({ index: -1, showManHoursEdit: false, manHours: 0, woBarcode: "" });
      const area = workPlace === workplaceEnum.line ? "Line" : workPlace === workplaceEnum.rkvLine ? "RkvLine" : "Lite ";
      manHoursApi.CreateWorkPackageInfo(manHours.manHours, manHours.woBarcode, area).then(res => {
        refresh();
      });
    }
  };

  const updateManHoursField = (field: Partial<ManHoursEdit>) => {
    setManHours((prevState) => {
      return {
        ...prevState,
        ...field,
      };
    });
  };

  const dispatchSelectedHangers = (dateFromParam: Date | undefined,
    dateToParam: Date | undefined,
    tailParam: string | null,
    filterByHangersParam: string[] | undefined,
    filterbyStatusParam: string[] | undefined,
    dueDateHourCyclesParam: boolean | undefined,
    dueDateExceedsFifteen: boolean | undefined) => {
    let filtersCopy;
    if (workPlace === workplaceEnum.line) {
      filtersCopy = {
        ...store.line.linefilters,
      };
    }
    else if (workPlace === workplaceEnum.rkvLine) {
      filtersCopy = {
        ...store.rkvLine.rkvLinefilters
      }
    }
    else {
      filtersCopy = {
        ...store.lite.litefilters,
      };
    }

    if (dateToParam) {
      filtersCopy.dateTo = dateToParam;
    }

    if (dateFromParam) {
      filtersCopy.dateFrom = dateFromParam;
    }

    if (tailParam != null) {
      filtersCopy.tail = tailParam;
    }
    else if (tailParam !== null) {
      filtersCopy.tail =  tailParam;
    }

    if (filterByHangersParam) {
      filtersCopy.filterByHangers = filterByHangersParam;
    }

    if (filterbyStatusParam) {
      filtersCopy.filterByStatus = filterbyStatusParam;
    }

    if (dueDateHourCyclesParam) {
      filtersCopy.dueDateHourCycles = !filtersCopy.dueDateHourCycles;
    }
    if (dueDateExceedsFifteen) {
      filtersCopy.dueDateExceedsFifteen = !filtersCopy.dueDateExceedsFifteen;
    }

    if (workPlace === workplaceEnum.line) {
      dispatch({
        type: "LINE_WORK_PACKAGE_OVERVIEW_FILTERS",
        linefilters: filtersCopy,
      });
    }
    else if(workPlace === workplaceEnum.rkvLine){
      dispatch({
        type: "RKV_LINE_WORK_PACKAGE_OVERVIEW_FILTERS",
        rkvLinefilters: filtersCopy,
      });
    }
    else {
      dispatch({
        type: "LITE_WORK_PACKAGE_OVERVIEW_FILTERS",
        litefilters: filtersCopy,
      });
    }
  }

  const generatePartsTableBody = (row: Part, index: number) => {
    return (
      <TableRow hover>
        <TableCell></TableCell>
        <TableCell>{row.REQ}</TableCell>
        <TableCell>{row.PN}</TableCell>
        <TableCell>{row.PartDescription}</TableCell>
        <TableCell>{row.SN} </TableCell>
        <TableCell>{row.REQ_QT}</TableCell>
        <TableCell>{row.Status}</TableCell>
        <TableCell>{row.EST_ARRIVAL_DT}</TableCell>
      </TableRow>
    )
  }

  const generateOutstandingPartsTableBody = (row: Part, index: number) => {
    return (
      <TableRow hover>
        <TableCell></TableCell>
        <TableCell>{row.tail_number}</TableCell>
        <TableCell onClick={() => redirectToMxi(row.REQ)}><IconButton><Link color="primary" /></IconButton></TableCell>
        <TableCell>{row.REQ}</TableCell>
        <TableCell>{row.PN}</TableCell>
        <TableCell>{row.PartDescription}</TableCell>
        <TableCell>{row.SN} </TableCell>
        <TableCell>{row.REQ_QT}</TableCell>
        <TableCell>{row.Status}</TableCell>
        <TableCell>{row.EST_ARRIVAL_DT}</TableCell>
        <TableCell>{row.schedual_start_date_string}</TableCell>
        <TableCell>{row.schedual_start_date_string}</TableCell>
      </TableRow>
    )
  }

  const generateStoppedPackagesDialog = (row: LinePackageOverviewObj, index: number) => {
    return (
      <TableRow>
        <TableCell></TableCell>
        <TableCell onClick={() => redirectToMxi(row.wo_barcode)}><IconButton><Link color="primary" /></IconButton></TableCell>
        <TableCell>{row.tail_number} </TableCell>
        <TableCell>{row.wo_name}</TableCell>
        <TableCell>{row.wo_barcode}</TableCell>
        <TableCell>{row.wo_number} </TableCell>
        <TableCell>{row.wo_status}</TableCell>
        <TableCell>{row.start_date_text}</TableCell>
        <TableCell>{row.due_date_text}</TableCell>
        <TableCell >
        </TableCell>
      </TableRow>
    )
  }

  const generateTableBody = (row: LinePackageOverviewObj, index: number) => {
    return (
      <React.Fragment>
        <TableRow
          hover
          className={
            row.start_date_bigger_than_due_date === true ? classes.backgroundColorRed :
              row.wo_status_of_the_day === "Day" ? classes.Morning :
                row.wo_status_of_the_day === "Night" ? classes.Night : ""
          }
        >
          <TableCell>
            <Grid direction="row">
              <Grid onClick={() => handleRowClicked(index)}>{(row.task_count !== 1 &&
                <IconButton aria-label="expand row" size="small" >
                  {index === rowClicked ? <ArrowDownward /> : <ArrowUpward />}
                </IconButton>)
              }
              </Grid>
              <Grid container  >
                <Grid item xs={6}>
                  {
                    (row.basket !== 0 && row.basket !== row.avail_basket) &&
                    <IconButton style={row.start_date_bigger_than_due_date ? { color: "white", backgroundColor: "black" } : { backgroundColor: "red" }} onClick={() => { openDialog(row.wo_number, row.wo_name) }}>
                      <ShoppingCart />
                    </IconButton>
                  }
                  {
                    (row.basket !== 0 && row.basket === row.avail_basket) &&
                    <IconButton onClick={() => { openDialog(row.wo_barcode, row.wo_name) }}>
                      <ShoppingCart />
                    </IconButton>
                  }
                </Grid>
                <Grid item xs={6}>
                  {row.is_wo_on_hold === true &&
                    (row.basket !== 0 && row.basket !== row.avail_basket) &&
                    <IconButton style={row.start_date_bigger_than_due_date ? { color: "white", backgroundColor: "black", marginLeft: "10px" } : { backgroundColor: "red", marginLeft: "10px" }} onClick={() => { onPackageOnHold() }}>
                      <StopCircle />
                    </IconButton>
                  }
                  {row.is_wo_on_hold === true &&
                    (row.basket !== 0 && row.basket === row.avail_basket) &&
                    <IconButton onClick={() => { onPackageOnHold() }}>
                      <StopCircle />
                    </IconButton>
                  }
                </Grid>
                <Grid>
                  {(row.is_wo_on_hold === true && row.basket === 0) &&
                    <IconButton onClick={() => { onPackageOnHold() }}>
                      <StopCircle />
                    </IconButton>
                  }
                </Grid>
              </Grid>
            </Grid>
          </TableCell>
          <TableCell onClick={() => redirectToMxi(row.wo_barcode)}><IconButton><Link color="primary" /></IconButton></TableCell>
          <TableCell style={{ width: '80px' }}>{row.tail_number} </TableCell>
          <TableCell>{row.wo_name}</TableCell>
          <TableCell>{row.wo_barcode.substring(row.wo_barcode.length - 5, row.wo_barcode.length)} </TableCell>
          <TableCell>{row.wo_number.replace('WO - ', '')} </TableCell>
          <TableCell>{row.wo_status}</TableCell>
          <TableCell>{row.start_date_text}</TableCell>
          <TableCell>{row.due_date_text}</TableCell>
          <TableCell onClick={() => updateManHoursField({ index: index, showManHoursEdit: true, manHours: row.man_hours, woBarcode: row.wo_barcode })}>
            {(manHours.showManHoursEdit && manHours.index === index) ?
              <Input
                required
                multiline={false}
                label_text={"Man hours"}
                value={manHours.manHours !== 0 ? manHours.manHours : 0}
                help_text="Man hours"
                onTextChange={(value: number) => updateManHoursField({ index: index, manHours: value })}
                type="number"
                width={100}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDownEnter(e)}
              />
              :
              (row.man_hours === 0 || row.man_hours === undefined) ? "" : row.man_hours
            }
          </TableCell>
        </TableRow>
        {(row.extra_tasks && (index === rowClicked)) && row.extra_tasks.map((extra_task, idx) => (
          <TableRow key={idx} >
            <TableCell  ></TableCell>
            <TableCell  ></TableCell>
            <TableCell  >{extra_task.name}</TableCell>
            <TableCell  >{extra_task.barcode}</TableCell>
            <TableCell  ></TableCell>
            <TableCell  ></TableCell>
            <TableCell  >{extra_task.start_date}</TableCell>
            <TableCell  >{extra_task.due_date}</TableCell>
          </TableRow>
        ))
        }
      </React.Fragment>
    )
  }
  return (
    <Grid xs={12} container direction="column">
      <Grid xs={12} sx={{ mb: 4 }} container direction="row">
        <Grid container item lg={1} md={2} sm={3} sx={{ mr: 2 }}>
          <DatePicker onDateChange={(date: Date) => dispatchSelectedHangers(date, undefined, null, undefined, undefined, undefined, undefined)} type={"Date"} label="Created from" selected={workPlace === workplaceEnum.line ? store.line.linefilters.dateFrom :  workPlace === workplaceEnum.rkvLine ? store.rkvLine.rkvLinefilters.dateFrom : store.lite.litefilters.dateFrom} />
        </Grid>

        <Grid container item lg={1} md={2} sm={3} sx={{ mr: 2 }}>
          <DatePicker onDateChange={(date: Date) => dispatchSelectedHangers(undefined, date, null, undefined, undefined, undefined, undefined)} type={"Date"} label="Created to" selected={workPlace === workplaceEnum.line ? store.line.linefilters.dateTo : workPlace === workplaceEnum.rkvLine ? store.rkvLine.rkvLinefilters.dateTo : store.lite.litefilters.dateTo} />
        </Grid>

        <Grid container item lg={1} md={1} sm={1} sx={{ mr: 2 }}>
          <Autocomplete
            fullWidth
            noOptionsText="No tails found"
            options={allTails}
            value={workPlace === workplaceEnum.line ? store.line.linefilters.tail : workPlace === workplaceEnum.rkvLine ? store.rkvLine.rkvLinefilters.tail : store.lite.litefilters.tail}
            clearOnEscape
            autoHighlight
            onChange={(e: any, newValue: string | null) => dispatchSelectedHangers(undefined, undefined, newValue, undefined, undefined, undefined, undefined)}
            renderInput={(params) => (
              <TextField {...params} label="Tails" variant="standard" />
            )}
          />
        </Grid>

        <Grid item lg={1} md={1} sm={1} sx={{ mr: 4 }}>
          <SelectMultiple
            options={ workPlace === workplaceEnum.line
              ? Object.values(hangarLineOptions)
              : workPlace === workplaceEnum.rkvLine
                ? Object.values(hangarRkvLineOptions)
                : Object.values(hangarLiteOptions)
            }
            onSelectedItem={(e: any) => dispatchSelectedHangers(undefined, undefined, null, e.target.value, undefined, undefined, undefined)}
            selectedItemsString={workPlace === workplaceEnum.line ? store.line.linefilters.filterByHangers : workPlace === workplaceEnum.rkvLine ? store.rkvLine.rkvLinefilters.filterByHangers : store.lite.litefilters.filterByHangers}
            width={150}
            filter_by_text={"Filter by Hangars"}
          />
        </Grid>

        <Grid item lg={1} md={1} sm={1} sx={{ mr: 8 }}>
          <SelectMultiple
            options={Object.values(statusOptions)}
            onSelectedItem={(e: any) => dispatchSelectedHangers(undefined, undefined, null, undefined, e.target.value, undefined, undefined)}
            selectedItemsString={workPlace === workplaceEnum.line ? store.line.linefilters.filterByStatus : workPlace === workplaceEnum.rkvLine ? store.rkvLine.rkvLinefilters.filterByStatus : store.lite.litefilters.filterByStatus}
            width={150}
            filter_by_text={"Filter by status"}
          />
        </Grid>

        <Grid lg={1.75} md={1.75} sm={1.75} sx={{ mr: 4, mt:1.5}}>
          <FormControlLabel
            control={<Checkbox checked={workPlace === workplaceEnum.line ? store.line.linefilters.dueDateHourCycles : workPlace === workplaceEnum.rkvLine ? store.rkvLine.rkvLinefilters.dueDateHourCycles : store.lite.litefilters.dueDateHourCycles}
              onChange={() => dispatchSelectedHangers(undefined, undefined, null, undefined, undefined, true, undefined)} name="checkedA" />}
            label="Due Date Hour/Cycles"
          />
        </Grid>
        { (workPlace === workplaceEnum.line ? store.line.linefilters.dueDateHourCycles  : (workPlace === workplaceEnum.rkvLine ? store.rkvLine.rkvLinefilters.dueDateHourCycles : store.lite.litefilters.dueDateHourCycles)) &&
          <Grid lg={1.25} md={1.25} sm={1.25} sx={{ mr: 4, mt:1.5}}>
            <FormControlLabel
              control={<Checkbox checked={workPlace === workplaceEnum.line ? store.line.linefilters.dueDateExceedsFifteen : workPlace === workplaceEnum.rkvLine ? store.rkvLine.rkvLinefilters.dueDateExceedsFifteen : store.lite.litefilters.dueDateExceedsFifteen}
                onChange={() => dispatchSelectedHangers(undefined, undefined, null, undefined, undefined, undefined, true)} name="checkedA" />}
              label="Due Date < 15"
            />
          </Grid>
        }

        <Grid lg={1.25} md={1.25} sm={1.25} sx={{  mt:1.5}}>
          <Tooltip title="Press R to refresh filtered data" type="primary" placement="top-start">
            <Button variant="contained" color="primary" size="medium" onClick={() => refresh()} >
              Filter
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <Dialog
        visible={deleteDialogOpen}
        max_width={"lg"}
        title={`Parts for  ${currentTaskName}`}
        context={
          <Grid>
            <Table
              headers={["Req", "PN", "Description", "SN", "Qty", "Status", "ETA Date"]}
              rows={partsInOrder}
              generateBody={(row: Part, index: number) => generatePartsTableBody(row, index)}
            />
          </Grid>
        }
        onClose={(status: any) => setDeleteDialogOpen(false)}
        strict={false}
        show_options={false}
      />

      <Dialog
        visible={deleteOutstandingPartsDialogOpen}
        max_width={"xl"}
        title={"Unavailable parts"}
        context={
          <Grid>
            {loading === true &&
              <Grid>
                <br />
                <LinearProgress />
              </Grid>
            }
            <Table
              headers={["Tail", "Mxi", "Req", "PN", "Description", "SN", "Qty", "Status", "ETA", "Start date", "Due date"]}
              rows={unavailableParts}
              generateBody={(row: Part, index: number) => generateOutstandingPartsTableBody(row, index)}
            />

          </Grid>
        }
        onClose={(status: any) => setDeleteOutstandingPartsDialogOpen(false)}
        strict={false}
        show_options={false}
      />

      <Dialog
        visible={stoppedDialogOpen}
        max_width={"xl"}
        title={"Packages on hold"}
        context={
          <Grid>
            <Table
              headers={["MXI", "Tail", "Work Package", "Barcode", "Wo number", "Work Package Status", "Start Date", "Due Date"]}
              rows={stoppedPackages}
              generateBody={(row: LinePackageOverviewObj, index: number) => generateStoppedPackagesDialog(row, index)}
            />
          </Grid>
        }
        onClose={(status: any) => setStoppedDialogOpen(false)}
        strict={false}
        show_options={false}
      />
      <Dialog
        visible={startDateLaterThanDueDateOpen}
        max_width={"xl"}
        title={`Start date later than due date (${reports.filter(s => s.start_date_bigger_than_due_date).length})`}
        context={
          <Grid>
            <Table
              headers={["Amount", "MXI", "Tail", "Work Package", "Barcode", "Wo number", "WO Status", "Start Date", "Due Date"]}
              rows={reports.filter(s => s.start_date_bigger_than_due_date)}
              generateBody={(row: any, index: number) => generateTableBody(row, index)}
              pagination={false}
              skipIndexCol={true}
            />
          </Grid>
        }
        onClose={(status: any) => setStartDateLaterThanDueDateOpen(false)}
        strict={false}
        show_options={false}
      />
      <Grid container direction="row" xs={12}>
        <Grid item>
          <Button style={{ marginBottom: '20px' }} variant="contained" color="primary" size="small" onClick={() => getOutstandingMaterials()}>
            <IconButton style={{ color: "red", marginRight: "10px" }}>
              <ShoppingCart />
            </IconButton>
            Unavailable Material
          </Button>
          <Button style={{ marginLeft: "20px", marginRight: "20px", marginBottom: '20px' }} variant="contained" color="primary" size="small" onClick={() => onPackageOnHold()}>
            <IconButton style={{ color: "white", marginRight: "10px" }}>
              <StopCircle />
            </IconButton>
            {`Packages on hold (${reports.filter(s => s.is_wo_on_hold).length})`}
          </Button>
          {reports.filter(x => x.start_date_bigger_than_due_date).length > 0 ?
            <Button style={{ marginBottom: '20px' }} variant="contained" color="primary" size="small" onClick={() => setStartDateLaterThanDueDateOpen(true)}>
              <IconButton style={{ marginRight: "10px", color: 'white' }}>
                <AlarmOn />
              </IconButton>
              {`Start date later than due date (${reports.filter(s => s.start_date_bigger_than_due_date).length})`}
            </Button>
            :
            <div></div>
          }
        </Grid>
        <Grid item style={{ marginLeft: '18px', marginTop: '10.5px' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Chip style={{ marginRight: '8px', backgroundColor: '#DDA0DD', fontSize: '16px', fontWeight: '700' }} label="Dayshift" variant="filled" />
            </Grid>
            <Grid item>
              <Chip style={{ marginRight: '8px', backgroundColor: '#FFA500', fontSize: '16px', fontWeight: '700' }} label="Nightshift" variant="filled" />
            </Grid>
            <Grid item>
              <Chip style={{ marginRight: '8px', backgroundColor: 'red', fontSize: '16px', fontWeight: '700' }} label="Start and due date conflict" variant="filled" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Table
        headers={["Amount", "MXI", "Tail", "Work Package", "Barcode", "Wo number", "WO Status", "Start Date", "Due Date", "MH"]}
        rows={reports}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        pagination={false}
        skipIndexCol={true}
      />
    </Grid>
  );
};

export default LineWorkPackageOverview;