import { useState, useEffect, FunctionComponent } from 'react';
import { Grid, TableCell, TableRow, Button } from '@mui/material';
import { clothingType, clothingData, ClothingColumns } from '../../types/clothRequests';
import clothingApi from './../../api/clothingRequests';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryLabel } from 'victory';
import { Dialog, Table, Typography } from '../../MaterialUiComponents';
import { Datepicker } from '@mobiscroll/react';
import { makeStyles } from '@mui/styles';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';

const useStyles = makeStyles(theme => ({
    chart: {
        width: "500px",
        height: "400px",
        display: "flex",
        justifyContent: "start"
    }
}));

type ClothingProps = {
    setLoading: Function,
    isLoading: boolean
}

const ClothingOverview: FunctionComponent<ClothingProps> = ({ setLoading, isLoading }) => {
    const [allClothingRequestsCopy, setAllCLothingRequestsCopy] = useState<clothingData[]>([]);
    const [allClothingTypes, setAllClothingTypes] = useState<clothingType[]>([]);
    const [allClothingRequests, setAllCLothingRequests] = useState<clothingData[]>([]);
    const [selectedClothingRequest, setSelectedCLothingRequests] = useState<clothingType[]>([]);
    const [columnData, setColumnData] = useState<ClothingColumns[]>([]);
    const [filteredclothingRequests, setFilteredClothingRequests] = useState<clothingData[]>();
    const [isModal, setIsModal] = useState<boolean>(false);

    const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
    const classes = useStyles();

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
        clothingApi.GetAllClothingDictionariesTypes().then(res => {
            setAllClothingTypes(res.types);
        });
        clothingApi.GetClothingRequests(null, null, null, "created_date", true).then(res => {
            setAllCLothingRequests(res.data);
            setAllCLothingRequestsCopy(res.data);
        });
    }

    useEffect(() => {
        if (allClothingTypes.length > 0) {
            return countClothingAmountsByType();
        }
    }, [allClothingTypes, allClothingRequests]);

    const countClothingAmountsByType = () => {
        const types: ClothingColumns[] = [];
        allClothingTypes.forEach((type, idx) => {
            let count = 0;
            allClothingRequests.forEach(request => {
                if (request.clothing_type_text === type.type) {
                    count += request.amount;
                }
            });
            types.push({ clothing: idx + 1, amount: count, type: type.type });
        });
        setColumnData(types);
        setLoading(false);
    }

    const onClothingTypeClicked = (props: any) => {
        setIsModal(true);
        const tempClothingType: clothingType = {
            id: allClothingTypes[props.index].id,
            type: allClothingTypes[props.index].type,
            amount: allClothingTypes[props.index].amount,
        }

        setSelectedCLothingRequests([tempClothingType]);
        const filteredRequests = allClothingRequests.filter(x => x.clothing_type_text === tempClothingType.type);
        setFilteredClothingRequests(filteredRequests);
    }

    const generateTableBody = (row: clothingData, index: number) => {
        return (
            <TableRow>
                <TableCell>{row.employee_name}</TableCell>
                <TableCell>{row.created_date_text}</TableCell>
                <TableCell>{row.amount}</TableCell>
            </TableRow>
        )
    }

    const onFilterClothingRequests = () => {
        clothingApi.GetClothingRequests(selectedDates[0]!, selectedDates[1]!, null, "created_date", true).then(res => {
            if (res.data.length === 0) {
                setAllCLothingRequests([]);
            }
            else {
                setAllCLothingRequests(res.data);
            }
        });
    }

    const onClearFilters = () => {
        setAllCLothingRequests(allClothingRequestsCopy);
    }

    const onDateChange = (ev: any) => {
        setSelectedDates(ev.value);
    };

    return (
        <Grid>
            <Grid xs={12} container style={{ justifyContent: 'left' }}>
                <Datepicker
                    controls={['calendar']}
                    select="range"
                    inRangeInvalid={true}
                    value={selectedDates}
                    onChange={(el: any) => onDateChange(el)}
                />
                <Button onClick={() => onFilterClothingRequests()}  >Submit</Button>
                <Button onClick={() => onClearFilters()}  >Clear filters</Button>
            </Grid>
            <Grid className={classes.chart} >
                {!isLoading &&
                    <VictoryChart
                        // adding the material theme provided with Victory
                        theme={VictoryTheme.material}
                        domainPadding={20}
                        animate={{ duration: 2000, easing: "bounce" }}
                        minDomain={{ y: 1 }}
                    >
                        <VictoryAxis
                            tickValues={[1, 2, 3, 4, 5, 6, 7]}
                            tickFormat={allClothingTypes.map(x => x.type)}
                            style={{
                                tickLabels: { angle: -50, verticalAnchor: "start" },
                                ticks: { stroke: "#B3B3B3", size: 5 },
                            }}
                            tickLabelComponent={<VictoryLabel style={{ fontSize: '6px' }} textAnchor={"middle"} dy={-7} dx={-15} />}
                            offsetY={49}
                        />
                        <VictoryAxis
                            dependentAxis
                            tickFormat={(x: any) => (` ${x}`)}
                        />
                        <VictoryBar
                            style={{
                                data: { fill: "#c43a31" }
                            }}
                            data={columnData}
                            x="clothing"
                            y="amount"
                            events={[{
                                target: "data",
                                eventHandlers: {
                                    onClick: () => {
                                        return [
                                            {
                                                target: "data",
                                                mutation: (props) => {
                                                    onClothingTypeClicked(props);
                                                }
                                            }
                                        ];
                                    }
                                }
                            }]}
                        />
                    </VictoryChart>
                }
                <Dialog
                    visible={isModal}
                    max_width={"sm"}
                    title={`${selectedClothingRequest[0] !== undefined && selectedClothingRequest[0].type}`}
                    context={
                        <Grid>
                            <Grid>
                                {selectedClothingRequest[0] !== undefined && selectedClothingRequest[0].type &&
                                    <Typography
                                        align='center'
                                        variant='h2'
                                        text={`Total amount of ${selectedClothingRequest[0].type} : ${columnData.find(x => x.type === selectedClothingRequest[0].type)?.amount}`}
                                        margin={[1, 0, 0, 0]}
                                    />
                                }
                            </Grid>
                            <Table
                                headers={["NAME", "DATE", "AMOUNT"]}
                                rows={filteredclothingRequests}
                                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                                pagination={false}
                                skipIndexCol={true}
                            />
                        </Grid>
                    }
                    onClose={(status: any) => { setIsModal(status) }}
                    strict={false}
                    show_options={false}
                />
            </Grid>
        </Grid>
    )
}
export default ClothingOverview;