
import { useEffect, useState, FunctionComponent } from 'react';
import { Card, CardContent, Grid, Switch, IconButton, TableCell, TableRow } from '@mui/material';
import { Link } from '@mui/icons-material';
import { Dialog, Tabs, Typography, Table } from '../../MaterialUiComponents';
import LineWorkpackageCard from './LineWorkpackageCard';
import { CalendarUI } from "../../components";
import LineWorkpackageNotes from './LineWorkpackageNotes';
import { redirectToMxi } from '../../config/mxiProvider';
import { Event } from '../../types/tasks';
import { LineExtraTaskInWOrkpackage, LineWorkPackages, DateRanges } from '../../types/line';
import lineApi from '../../api/line';

type LineSupervisorPlanedTasksProps = {
    isAdmin: Boolean,
    accessGroups: string[],
    location: string,
    setLoading?: Function
}

// This component is used to display the planned tasks for the line supervisor.
// KEF/LINE and RKV/LINE

const LineSupervisorPlanedTasks: FunctionComponent<LineSupervisorPlanedTasksProps> = ({ isAdmin, accessGroups, location, setLoading}) => {
    let isDay = true;
    const hr = (new Date()).getHours();
    if (hr >= 6 && hr <= 18) {
        isDay = true;
    } else {
        isDay = false;
    }
    const [isDayNight, setToggleDayNight] = useState<boolean>(isDay);
    const statusOptions = ["COMPLETE", "ACTV", "COMMIT", "CANCEL", "IN WORK", "PAUSE"];
    const [calendarEvents, setCalendarEvents] = useState(Array<Event>());
    const [eventDialogOpen, setEventDialogOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<LineWorkPackages>();
    const [tabValue, setTabValue] = useState(0);
    const [workpackageTasks, setWorkpackageTasks] = useState<LineExtraTaskInWOrkpackage[]>([]);
    const [filteredTasks, setFilteredTasks] = useState<Event[]>([]);
    const isLinePlanner = accessGroups.includes("Line Planner") || accessGroups.includes("Line Planner View") || accessGroups.includes("Rvk Line Edit") || accessGroups.includes("Rvk Line Edit");

    const refresh = () => {
        lineApi.GetLinePacketsForCalendar(statusOptions, [location]).then(res => {
            let tempTasks: Array<Event> = []
            if (res.status === true) {
                res.data.forEach(task => {
                    task.start = new Date(task.start);
                    task.end = new Date(task.end);
                    tempTasks.push(task);
                });
            }
            setCalendarEvents(tempTasks);
            setFilteredTasks(tempTasks.filter(s => s.task_details.is_day === isDayNight));
            if(setLoading){
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        refresh();
    }, []);

    const onEventClick = (event: Event) => {
        const taskDetails = event.task_details;
        if (taskDetails.status !== "HEADER") {
            const lineWorkpackage = {
                wo_number: taskDetails.wo_number,
                wo_barcode: taskDetails.barcode,
                status: taskDetails.status,
                wo_name: event.title,
                mxi_start_date_text: event.task_details.mxi_start_date_text,
                mxi_end_date_text: event.task_details.mxi_end_date_text,
                mxi_due_date_text: event.task_details.due_date_text,
                maintener_planned_date_text: event.task_details.maintener_planned_date_text,
                tail_number: event.task_details.tail_number,
                scheduled_task_id: event.scheduled_task_id,
                is_task_critical: event.task_details.is_task_critical,
                is_on_hold: event.task_details.is_on_hold,
                is_on_time: event.task_details.is_on_time,
                package_duration_text: event.task_details.package_duration_text,
                aircraft_arrival_and_departure_times: event.task_details.aircraft_arrival_and_departure_times
            } as LineWorkPackages;
            setSelectedEvent(lineWorkpackage);

            lineApi.GetTasksByLinePackageWoNumber(event.task_details.wo_number).then(res => {
                setWorkpackageTasks(res.data);
            })
            setEventDialogOpen(true);
        }
    }
    const onClose = () => {
        setWorkpackageTasks([] as Array<LineExtraTaskInWOrkpackage>)
        setEventDialogOpen(false);
        setTabValue(0);
    }
    const generateTableBody = (row: DateRanges, index: number) => {
        return (
            <TableRow >
                <TableCell>{index}</TableCell>
                <TableCell>{row.arrival_date_text}</TableCell>
                <TableCell>{row.departure_date_text}</TableCell>
            </TableRow>
        )
    }
    const onDayNightToggle = () => {
        setFilteredTasks(calendarEvents.filter(task => task.task_details.is_day === !isDayNight));
        setToggleDayNight(!isDayNight);
    }
    const tabNames = isLinePlanner ? ["Task", "Notes", "Arrival & Departure"] : ["Task", "Notes"];
    return (
        <Grid>
            <Grid container>
                <Grid xs={2} container>
                    <Typography align='center' variant='h3' text={"Night"} margin={[1, 0, 4, 0]} />
                    <Switch
                        checked={isDayNight}
                        onChange={() => onDayNightToggle()}
                        color="secondary"
                        name="checkedB"
                    />
                    <Typography align='center' variant='h3' text={"Day"} margin={[1, 0, 0, 0]} />
                </Grid>
                <Grid xs={12}>
                    <CalendarUI
                        edit={false}
                        totalPlanned={"-1"}
                        fullScreen={true}
                        setFullScreen={(fullScreen: boolean) => console.log("full screen")}
                        clickedTaskDate={undefined}
                        tasksOnCalendar={filteredTasks}
                        draggedTasks={[]}
                        onDropFromOutside={() => { }}
                        onEventDrop={() => { }}
                        onCheckboxClicked={() => { }}
                        onEventClick={(event: Event) => { onEventClick(event); }}
                        isLinePlanner={isLinePlanner}
                    />
                    <Dialog
                        visible={eventDialogOpen}
                        max_width={"md"}
                        title={selectedEvent?.wo_name === undefined ? "" : selectedEvent!.wo_name}
                        context={
                            <Grid item>
                                <Tabs variant="fullWidth" tabNames={tabNames} tabValue={tabValue} onChangeTabValue={(tabValue: number) => setTabValue(tabValue)} />
                                {tabValue === 0 &&
                                    <Grid>
                                        <LineWorkpackageCard task={selectedEvent} type='LINE' onOpenDialogClicked={() => { }} />
                                        <Typography align='center' variant='h2' text={`Tasks(${workpackageTasks.length})`} margin={[1, 0, 0, 0]} />
                                        <Grid>
                                            {workpackageTasks.map(task => {
                                                return (
                                                    <Grid style={{ margin: '2%' }}>
                                                        <Card >
                                                            <CardContent>
                                                                <Grid>
                                                                    <Typography text={`${task.name}`} color={'primary'} variant={'h3'} align={'center'} margin={[0, 0, 4, 0]} />
                                                                </Grid>
                                                                <Grid>
                                                                    <Typography text={`Status: ${task.status}`} color={'primary'} variant={'h3'} align={'center'} margin={[0, 0, 1, 0]} />
                                                                </Grid>
                                                                <Grid>
                                                                    <Typography text={`Start date: ${task.start_date}`} color={'primary'} variant={'h4'} align={'center'} margin={[0, 0, 1, 0]} />
                                                                </Grid>
                                                                <Grid>
                                                                    <Typography text={`Due date: ${task.due_date}`} color={'primary'} variant={'h4'} align={'center'} margin={[0, 0, 1, 0]} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <IconButton onClick={() => redirectToMxi(task.barcode)} style={{ marginLeft: 8 }}><Link color="primary" /></IconButton>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>}
                                {tabValue === 1 && <LineWorkpackageNotes isEditMode={isAdmin} task={selectedEvent} />}
                                {tabValue === 2 &&
                                    <Grid>
                                        <Typography text={selectedEvent.tail_number} align='center' variant='h2' margin={[1, 0, 1, 0]} />
                                        <Table
                                            headers={["Arrival", "Departure"]}
                                            rows={selectedEvent?.aircraft_arrival_and_departure_times}
                                            generateBody={(row: any, index: number) => generateTableBody(row, index)}
                                            defaultRowsPerPage={25}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        }
                        onClose={(status: any) => { onClose() }}
                        strict={false}
                        show_options={false}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
export default LineSupervisorPlanedTasks;